/* eslint-disable no-console */
// RoutController.js
// ========
import Vue from "vue";

let forGlob = new Vue();

async function getCityList() {
    let city_list
        , cityListName = 'city_list';
    if (sessionStorage.getItem(cityListName)) {
        city_list = JSON.parse(sessionStorage.getItem(cityListName));
    } else {
        let response = await Vue.prototype.$http.get(Vue.prototype.$config.api_url + 'City.getList');
        sessionStorage.setItem(cityListName, JSON.stringify(response.data.city));
        city_list = response.data.city;
    }
    forGlob.$data.$cityList = city_list;
    return city_list;
}

const beforeResolve = (router) => {
    router.beforeResolve(async (to, from, next) => {
        const listCity = await getCityList();
        switch (to.name) {
            case null:
                next({name: 'NotFound'});
                break;
            case "Home":
                if (typeof to.params.city === 'undefined') {
                    if (forGlob.$myCity) {
                        next({name: 'Home', params: {city: forGlob.$myCity.index}});
                    } else {
                        next({name: 'cityList'});
                    }
                }
                break;
        }
        /* Check: has city in City list */
        if (typeof to.params.city !== 'undefined') {
            if (Object.keys(listCity).length > 0 && !listCity[to.params.city]) {
                next({name: 'NotFound'});
            } else if (!forGlob.$myCity || forGlob.$myCity.index !== to.params.city) {
                forGlob.$myCity = listCity[to.params.city];
                forGlob.$language = listCity[to.params.city].alpha_2;
            }
        } else {
            // forGlob.$myCity = null;
        }
        next();
    })

};

export default {beforeResolve};
