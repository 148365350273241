/* eslint-disable no-console */
// RoutController.js
// ========

import Vue from "vue";
import axios from "axios";
import { router } from './main';

const globalData = new Vue({
    data: {
        $language: 'RU',
        $noPartner: false,
        $user: null,
        $pageUpdate: 0,
        $leftMenuBurger: false,
        $newsRightPlugin: null,
        $Visibility: false,
        $myCity: null,
        $shop: {
            product_list: null,
            filtered_list: null,
        },
        $logo_og: 'https://new.okaygorod.com/images/logoHD.png',
        $cityList: [],
        $ads_count: 0,
        $ads_list: [],
        $yandex_id: 1,
        $celat: false,
        $stopCelat: false,
        $noMenu: false,
        $celat_list: []
    },
    methods: {},
});

Vue.prototype.$scrollToTop = (ms = 100) => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, ms);
};

Vue.mixin({
    directives: {
        $autogrow: {
            componentUpdated: function (el) {
                el.style.height = "auto";
                el.style.height = (el.scrollHeight) + "px";
            }
        }
    },
    computed: {
        $language: {
            get: function () {
                if (localStorage.getItem('language')) {
                    globalData.$data.$language = localStorage.getItem('language');
                }
                return this.$locales[globalData.$data.$language] ? globalData.$data.$language : 'RU';
            },
            set: function (newData) {
                if (!newData) {
                    localStorage.removeItem('language');
                } else {
                    localStorage.setItem('language', newData);
                }
                console.log(newData);
                globalData.$data.$language = this.$locales[newData] ? newData : 'RU';
            }
        },
        $noPartner: {
            get: function () {
                /*if (localStorage.getItem('noPartner') && !globalData.$data.$noPartner) {
                    globalData.$data.$noPartner = JSON.parse(localStorage.getItem('noPartner'));
                }*/
                return globalData.$data.$noPartner;
            },
            set: function (newData) {
                localStorage.setItem('noPartner', newData);
                globalData.$data.$noPartner = newData;
            }
        },
        $user: {
            get: function () {
                if (localStorage.getItem('user') && !globalData.$data.$user) {
                    globalData.$data.$user = JSON.parse(localStorage.getItem('user'));
                    axios.defaults.headers.post['X-Access-Token'] = globalData.$data.$user.token;
                    axios.defaults.headers.get['X-Access-Token'] = globalData.$data.$user.token;
                }
                return globalData.$data.$user
            },
            set: function (newData) {
                if (!newData) {
                    localStorage.removeItem('user');
                    axios.defaults.headers.post['X-Access-Token'] = null;
                    axios.defaults.headers.get['X-Access-Token'] = null;
                } else {
                    localStorage.setItem('user', JSON.stringify(newData));
                    axios.defaults.headers.post['X-Access-Token'] = newData.token;
                    axios.defaults.headers.get['X-Access-Token'] = newData.token;
                }
                globalData.$data.$user = newData;
            }
        },
        $pageUpdate: {
            get: function () {
                return globalData.$data.$pageUpdate
            },
            set: function () {
                globalData.$data.$pageUpdate++;
            }
        },
        $leftMenuBurger: {
            get: function () {
                return globalData.$data.$leftMenuBurger;
            },
            set: function () {
                globalData.$data.$leftMenuBurger = (!globalData.$data.$leftMenuBurger);
            }
        },
        $newsRightPlugin: {
            get: function () {
                return globalData.$data.$newsRightPlugin;
            },
            set: function (news) {
                globalData.$data.$newsRightPlugin = news;
            }
        },
        $Visibility: {
            get: function () {
                return globalData.$data.$Visibility;
            },
            set: function () {
                globalData.$data.$Visibility = (!globalData.$data.$Visibility);
            }
        },
        $myCity: {
            get: function () {
                if (!globalData.$data.$myCity && localStorage.getItem('myCity')) {
                    globalData.$data.$myCity = JSON.parse(localStorage.getItem('myCity'));
                }
                return globalData.$data.$myCity;
            },
            set: function (set) {
                localStorage.setItem('myCity', JSON.stringify(set));
                globalData.$data.$myCity = set;
            }
        },
        $shop: {
            get: function () {
                return globalData.$data.$shop;
            },
            set: function (shop) {
                globalData.$data.$shop = shop;
            }
        },
        $cityList: {
            get: () => {
                let cityListName = 'city_list';
                if (globalData.$data.$cityList.length === 0) {
                    if (sessionStorage.getItem(cityListName)) {
                        globalData.$data.$cityList = JSON.parse(sessionStorage.getItem(cityListName));
                    } else {
                        Vue.prototype.$http.get(Vue.prototype.$config.api_url + 'City.getList')
                            .then((response) => {
                                sessionStorage.setItem(cityListName, JSON.stringify(response.data.city));
                                globalData.$data.$cityList = response.data.city;
                            });
                    }
                }
                return globalData.$data.$cityList;
            },
            set: function (cityList) {
                globalData.$data.$cityList = cityList;
            }
        },
        $ads_count: {
            get: function () {
                return globalData.$data.$ads_count;
            },
            set: function (count) {
                globalData.$data.$ads_count = count;
            }
        },
        $ads_list: {
            get: function () {
                return globalData.$data.$ads_list;
            },
            set: function (list) {
                globalData.$data.$ads_list = list;
            }
        },
        $yandex_id: {
            get: function () {
                return globalData.$data.$yandex_id;
            },
            set: function (count) {
                globalData.$data.$yandex_id = count;
            }
        },
        $logo_og: {
            get: function () {
                return globalData.$data.$logo_og;
            },
        },
        $celat: {
            get: function () {
                return globalData.$data.$celat;
            },
            set: function (value) {
                globalData.$data.$celat = value;
            }
        },
        $stopCelat: {
            get: function () {
                return globalData.$data.$stopCelat;
            },
            set: function (value) {
                globalData.$data.$stopCelat = value;
            }
        },
        $noMenu: {
            get: function () {
                if (this.$route.query && this.$route.query.noMenu) {
                    globalData.$data.$noMenu = true;
                }
                return globalData.$data.$noMenu;
            },
            set: function (value) {
                globalData.$data.$noMenu = value;
            }
        },
        $celat_list: {
            get() {
                return globalData.$data.$celat_list;
            },
            set: function (list) {
                globalData.$data.$celat_list = list;
            }
        },
    },
    methods: {
        $celatGetList() {
            let url = this.$config.api_url + this.$route.params.city + '/Celat.getList';
            this.$http.get(url, {
                params: {
                    limit: 10
                }
            }).then((response) => {
                globalData.$data.$celat_list = globalData.$data.$celat_list.concat(response.data.response);
                if (globalData.$data.$celat_list.length === 0) {
                    globalData.$data.$stopCelat = true;
                }

            })
        }
    },
});


axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // handle error
        if (error.response) {
            let code = error.response.status;
            let title = '';
            if(code === 400) {
                router.push({ name: 'NotFound' });
            }
            try {
                if (typeof (error.response.data.response.code) !== 'undefined') {
                    code = error.response.data.response.code
                }
                if (typeof (error.response.data.response.message) !== 'undefined') {
                    title = error.response.data.response.message
                }
            } catch (e) {
                console.log(e);
            }
            Vue.prototype.$notify({
                group: 'error',
                type: 'error',
                text: 'Error Code:' + code,
                title
            });
            console.log(error.response);
            return Promise.reject(error);
        }
    });


Vue.prototype.$htmContext = (text) => {
    text = text.replace(/\u00a0/g, ' ');
    text = text.replace(/<br\s*[/]?>\r\n/gi, '<br>');
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    let text1 = text.replace(exp, "<a style='word-break: break-all;' class='link_carryover' target='_blank' rel='nofollow' href='$1'>$1</a>");
    let exp2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    return text1.replace(exp2, '$1<a style="word-break: break-all;" class="link_carryover" target="_blank" rel="nofollow" href="http://$2">$2</a>');
};

Vue.prototype.$findPhoneNumber = (text) => {
    let exp = /((\+7|8|7)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6}))/g;
    if (text) {
        text = text.replace(exp, "<a  rel='nofollow' href='tel:$1'>$1</a>");

    }
    return text;
};


Vue.prototype.$phonemask = (text) => {
    let x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    text = !x[2] ? x[1] : '(' + x[1] + ')' + x[2] + (x[3] ? '-' + x[3] : '');
    return text;
};

Vue.prototype.$getMyCity = () => {
    if (localStorage.getItem('myCity')) {
        return JSON.parse(localStorage.getItem('myCity'));
    }
};

Vue.prototype.$getCityList = () => {
    return this.$cityList;
};

Vue.prototype.$mobileWidth = () => {
    return window.innerWidth < 768;

};

Vue.prototype.$averageWidth = () => {
    return window.innerWidth < 992;

};

Vue.prototype.$pcWidth = () => {
    return window.innerWidth > 1545;

};

Vue.prototype.$goBack = () => {
    window.history.back();
};

Vue.prototype.$img = (el) => {
    let img = new Image();
    img.src = el;
    return img;
};

Vue.prototype.$toString = (array) => {
    let data;
    data = array.map(array => {
        return array.keyword;
    })
    if (data.length) {
        return data.join(', ');
    }
};

Vue.prototype.$cutText = (value, limit) => {
    return value.length > limit ?
        value.slice(0, limit - 3) + '...' :
        value;
};

Vue.prototype.$ios = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);

};

Vue.prototype.$menu = () => {
    let a = document.getElementById("mobileMenuRight");
    let e = document.querySelector('.menu_box');
    if (globalData.$data.$leftMenuBurger) {
        a.style.height = '0px';
        e.style.opacity = '0';
        e.style.paddingBottom = '0px';
        globalData.$data.$leftMenuBurger = false;
    } else {
        a.style.height = '100%';
        e.style.paddingBottom = '80px';
        setTimeout(function () {
            e.style.opacity = '1';
        }, 500);
        globalData.$data.$leftMenuBurger = true;

    }
};
