<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
// import LayoutDefault from './layouts/LayoutDefault.vue';
import { mapState, mapActions } from "vuex";

export default {
    name: "app",
    // metaInfo() {
    //     return {
    //         script: [{ src: "https://yandex.ru/ads/system/context.js", async: true, defer: false }],
    //     };
    // },
    components: {
        // LayoutDefault
    },
    data() {
        return {};
    },
    methods: {},
    created() {
        let script1 = document.createElement('script')
        script1.innerHTML = "window.yaContextCb = window.yaContextCb || []";
        document.head.appendChild(script1)

        let script2 = document.createElement('script')
        script2.setAttribute('src', 'https://yandex.ru/ads/system/context.js')
        script2.setAttribute('async', 'async')
        document.head.appendChild(script2)
    },
    watch: {},
};
</script>
<style></style>
