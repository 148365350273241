const Etalon = () => import('./components/Etalon');


// FIXED PAGE

// Новостные разделы
const InterestingList = () => import('./components/App/Interesting/InterestingList');
const NewsGetNew = () => import('./components/App/News/NewsGetNew');

// Новости
const News = () => import('./components/App/News/NewsGet');
const NewsList = () => import('./components/App/News/NewsListMain');

// Городская лента
// const CommunicationList = () => import('./components/App/Communication/CommunicationList');

// Вспомогательные страницы
const Regulation = () => import('./components/Regulation'); // Правила
const Edition = () => import('./components/Edition'); // Редакция
const Help = () => import('./components/Help'); // Помощь 
const Advertising = () => import('./components/Advertising'); // Реклама 
const CityList = () => import('./components/App/City/CityList'); // Выбор города
const Weather = () => import('./components/App/Weather/Weather'); // Погода
const Search = () => import('./components/App/Search/Search'); // Поиск
const Copyright = () => import('./components/copyright'); // Правила

const AdsCategory = () => import('./components/App/Ads/AdsCategory');
const AdsList = () => import('./components/App/Ads/AdsList/index');
const AdsGet = () => import('./components/App/Ads/AdsGet/index');


// Афиша
const PosterList = () => import('./components/App/Poster/PosterListNew');
const Post = () => import('./components/App/Poster/PostGet');


const Home = () => import('./components/App/Home/Home');
const Offer = () => import('./components/App/News/Offer');
// const CommunicationGet = () => import('./components/App/Communication/CommunicationGet/index');
const UserRegistration = () => import('./components/App/User/UserRegistration');

const SharesList = () => import('./components/App/Shares/SharesListNew');
const Shares = () => import('./components/App/Shares/SharesGet');

const CatalogCategory = () => import('./components/App/Catalog/CatalogCategory');
const CatalogList = () => import('./components/App/Catalog/CatalogList');


const Favorite = () => import('./components/App/Favorite/index');
const Login = () => import('./components/App/Login/Login');
const Landing = () => import('./components/Landing/Landing');
const DeveloperLanding = () => import('./components/Landing/Developer');

const Developer = () => import('./components/Developer/Developer');

const OrganisationGet = () => import('./components/App/Organisation/orgNew');

const PasswordReset = () => import('./components/App/User/PasswordReset');

const OkLogin = () => import('./components/App/Login/Ok');
const NotFound = () => import('./components/not');

// Пользователь
const User = () => import('./components/App/User/UserGet');
const UserLogin = () => import('./components/App/User/UserLogin');
const UserEmailConfirm = () => import('./components/App/User/EmailConfirm');

// Франшиза
const Partnership = () => import('./components/Landing/Partnership/Partnership');


const routes = [
    {
        path: '/development',
        name: 'DeveloperLanding',
        component: DeveloperLanding
    },

    {
        path: '/n',
        components: {
            default: () => import('@/layouts/LayoutNoCity.vue'),
            
        },
        children: [
        ]
    },

    {
        path: '',
        components: {
            default: () => import('@/layouts/LayoutDefault.vue'),
        },
        children: [
            // Эталон
            { path: '/:city/etalon', name: 'Etalon', component: Etalon },

            // Новости
            { path: '/:city/news/list', name: 'NewsList', component: NewsList },
            { path: '/:city/news/:id', name: 'News', component: News },
            
            // Новостные разделы
            { path: '/:city/interesting/list/:id', name: 'InterestingList', component: InterestingList },
            { path: '/:city/interesting/article/:id', name: 'NewsGetNew', component: News },

            // Городская лента
            // { path: '/:city/communication', name: 'CommunicationList', component: CommunicationList },
            // { path: '/:city/communication/:id', name: 'CommunicationGet', component: CommunicationGet },

            // Вспомогательные страницы
            { path: '/:city/regulation', name: 'Regulation', component: Regulation },
            { path: '/:city/edition', name: 'Edition', component: Edition },
            { path: '/:city/help', name: 'Help', component: Help },
            { path: '/:city/advertising', name: 'Advertising', component: Advertising },
            { path: '/:city/weather', name: 'Weather', component: Weather },
            { path: '/:city/registration', name: 'UserRegistration', component: UserRegistration },
            { path: '/:city/search', name: 'Search', component: Search, props: true },
            { path: '/:city/copyright', name: 'Copyright', component: Copyright },


            { path: '/:city/ads/category/:id?', name: 'AdsCategory', component: AdsCategory },
            { path: '/:city/ads/list/:id', name: 'AdsList', component: AdsList },
            { path: '/:city/ads/:id', name: 'AdsGet', component: AdsGet },


            // Афиша
            { path: '/:city/poster/list', name: 'PosterList', component: PosterList },
            { path: '/:city/poster/:id', name: 'Post', component: Post },




            { path: '/developer', name: 'Developer', component: Developer },

            // Landing   
            { path: '/application', name: 'Landing', component: Landing },

            // Франшиза Partnership    
            { path: '/partnership', name: 'Partnership', component: Partnership },

            { path: '/cityList', name: 'cityList', component: CityList },
            { path: '/login/ok', name: 'OkLogin', component: OkLogin },

            //redirect Android and IOS download
            {
                path: '/android',
                redirect: function() {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.goroda.cities'
                }
            },
            {
                path: '/ios',
                redirect: function() {
                    window.location.href = 'https://apps.apple.com/ru/app/id1361334339'
                }
            },
            {
                path: '/appgallery',
                redirect: function() {
                    window.location.href = 'https://appgallery.huawei.com/#/app/C103438881'
                }
            },
            //to first became all pages when logic not using city
            //its used for route controller
            // all pages where city is important
            { path: '/:city/', name: 'Home', component: Home },
            { path: '/:city/news/', redirect: { name: 'NotFound' } },

            { path: '/:city/news/offer/set', name: 'Offer', component: Offer },
            { path: '/:city/shares/list', name: 'SharesList', component: SharesList },
            { path: '/:city/shares/:id', name: 'Shares', component: Shares },


            { path: '/:city/favorite', name: 'Favorite', component: Favorite },
            { path: '/:city/login', name: 'Login', component: Login, props: true },

            { path: '/:city/organisation/', redirect: { name: 'NotFound' } },
            { path: '/:city/organisation/cat/:id?', name: 'OrganisationCat', component: CatalogCategory },
            { path: '/:city/organisation/list/:id?', name: 'OrganisationList', component: CatalogList },
            { path: '/:city/organisation/:id', name: 'OrganisationGet', component: OrganisationGet },


            // Пользователь
            { path: '/:city/user', name: 'User', component: User },
            { path: '/:city/login', name: 'UserLogin', component: UserLogin },
            { path: '/:city/password/reset', name: 'PasswordReset', component: PasswordReset },
            { path: '/email/confirm', name: 'UserEmailConfirm', component: UserEmailConfirm, props: true },

            //default route --redirect to Home
            // {path: '/', redirect: {name: 'cityList'}}, //redirect in controller TODO delete after
            { path: '/404', name: 'NotFound', component: NotFound },
            { path: '/:city/*', redirect: { name: 'NotFound' } },
            { path: '/', redirect: { name: 'Home' } },


            {
                path: '/apple-app-site-association',
            }
        ]
    },

];


export default routes;