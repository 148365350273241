import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import config from './config';
// import store from './store'

const locales = () => import('@/components/Language/locales');
Vue.prototype.$locales = locales;

Vue.prototype.$http = axios;

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import Notifications from 'vue-notification';

Vue.use(Notifications);

Vue.prototype.$config = config;

import routes from './routes';

Vue.use(VueRouter);

const VueCarousel = () => import('vue-carousel');

Vue.use(VueCarousel);

//v-lazy-image Lazy Load Images
const VLazyImagePlugin = () => import('v-lazy-image');
Vue.component('v-lazy-image',VLazyImagePlugin);
// Vue.use(VLazyImagePlugin);

VLazyImagePlugin();
// VueWaypoint

const VueWaypoint = () => import('vue-waypoint');
 
Vue.use(VueWaypoint)

const VueTextareaAutogrowDirective = () => import('vue-textarea-autogrow-directive');
Vue.use(VueTextareaAutogrowDirective);


//Start Vue Masked Input
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

const MaskedInput = () => import('vue-masked-input');

Vue.component('masked-input',MaskedInput);
// End Vue Masked Input


//Bootstrap
import BootstrapVue from 'bootstrap-vue';

Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//End Bootstrap

//vue-tinybox
const Tinybox = () => import('vue-tinybox');
Vue.component('Tinybox', Tinybox);
//END vue-tinybox


//Start validate
const Vuelidate = () => import('vuelidate');

Vue.use(Vuelidate);
// End validate

//fortawesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {fab} from '@fortawesome/free-brands-svg-icons';

library.add(far);
library.add(fas);
library.add(fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);
//End fortawesome

//timezone  https://momentjs.com/timezone/
import moment from 'moment-timezone';


moment.tz.setDefault('Europe/Paris');
moment.locale('ru');
Vue.prototype.$moment = moment;
//End timezone

Vue.config.productionTip = false;


const VueGeoLocation = () => import('vue-browser-geolocation');

Vue.use(VueGeoLocation);

import './globalFunctions';

// Importing the global css file
import "@/assets/global.css"
import "@/assets/main.css"
import "@/assets/newStyle.css"
// import "@/assets/bootstrap.min.css"


export const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes
});

import cityCtr from './Controllers/RoutController';

cityCtr.beforeResolve(router);

import App from './App.vue'


Vue.component('PreLoadBox', () => import('./components/Preloader/coin'));
Vue.component('Spiner', () => import('./components/Preloader/spiner'));
Vue.component('AdsPlaginNew', () => import('./components/Ads/AdsPlaginNew'));
Vue.component('YouTube', () => import('./components/YouTube/index'));

import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
/* eslint-disable no-console */
// Ошибки
window.onerror = function(message, source, line, column, error) {

  console.log(`Ошибка: ${message} \nURL: ${source} \nLine: ${line}/${column} \nError: ${error}`);

}

Vue.config.errorHandler = function(err, vm, info) {

  console.log(`Ошибка: ${err.toString()}\nИнформация: ${info}`);

}


new Vue({
    router,
    render: h => h(App),
}).$mount('#app');

/** Yandex Metrika */
import VueYandexMetrika from 'vue-yandex-metrika';

Vue.use(VueYandexMetrika, {
    id: 48705500,
    router,
    debug: false,
    env: process.env.NODE_ENV
    // other options
});

/** END Yandex Metrika */

/** Analytics Google. */
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
    id: 'UA-50154500-9',
    router
});
/**END Analytics Google. */

/** Analytics Google. */
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: 'G-XBBZEPFF5Z' }
})
/**END Analytics Google. */

























