const config = {
    api_url: 'https://api.okaygorod.com/api/v1/',
    url: 'https://okaygorod.com',
    api_route: 'https://api.openrouteservice.org/v2/',
    api_new: 'https://api.okgorod.ru/api/v1/',
    api_new_storage: 'https://api.okgorod.ru/storage/',
    api_okgorod: 'https://api.okgorod.ru/api/v1/',
    api_fssp: 'https://api-ip.fssprus.ru/api/v1.0/', // https://api-ip.fssprus.ru/swagger
//     api_url: 'http://okaygorodadministration.local/api/v1/',
    API_TIME_ZONE: 'Europe/Paris',
    media: 'https://media.okaygorod.com/',
    // media: 'https://city.ooogoroda.mobi/'
};

export default config;
